import { Image, Text, View } from "@react-pdf/renderer";
import { Fragment } from "react";
import { styles } from "./styles";
import { currencyFormatter } from "../../../../../utils/currencyFormatter";

export const InvoiceTableBody = ({ products }) => {
  return (
    <>
      {products.map((product) => (
        <Fragment key={product.id}>
          <View style={{ width: "100%", flexDirection: "row" }}>
            <View
              style={[
                styles.tbody,
                { alignItems: "center", justifyContent: "center" },
              ]}
            >
              <Image
                src={`https://democrata-public-files.s3.amazonaws.com/140x80/${product.referencia
                  }.png?${new Date().getTime()}`}
                style={{ width: 60, height: 60, objectFit: "contain" }}
              />

              <Text>
                {product.linha} - {product.cor}
              </Text>
            </View>

            <View style={[styles.tbody]}>
              <Text>{product.referencia}</Text>
            </View>
            <View style={[styles.tbody]}>
              <Text>{product.ncm}</Text>
            </View>
            <View style={[
              styles.tbody,
              styles.tbody2,
              { flexDirection: "column", gap: 1 },
            ]}>
              <View
                style={[
                  styles.tbody2,
                  { flexDirection: "row", gap: 1, justifyContent: "flex-start", height: "100%", alignItems: "center", alignSelf: "baseline", borderRight: 0 },
                ]}
              >
                {product.gradeProduto && product.gradeProduto?.map((grade) => (
                  <View
                    style={{
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "10%",
                      gap: 1,
                    }}
                  >
                    <Text style={styles.gradeItemHeader}>{grade.nro}</Text>
                    <Text style={styles.gradeItemContent}>{grade.estoque}</Text>
                  </View>
                ))}
              </View>
            </View>
            <View style={styles.tbody}>
              <Text>{product.desconto || 0}% </Text>
            </View>
            <View style={styles.tbody}>
              <Text>{product.quantidade}</Text>
            </View>
            {/* <View style={styles.tbody}>
              <Text>{product.caixas || 0}</Text>
            </View> */}
            <View style={styles.tbody}>
              <Text>{currencyFormatter(product.preco)} </Text>
            </View>
            <View style={styles.tbody}>
              <Text>{currencyFormatter(product.unitario)} </Text>
            </View>
          </View>
        </Fragment>
      ))}
    </>
  );
};
