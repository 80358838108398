import { Text, View } from "@react-pdf/renderer";
import { styles } from "./styles";
import { formatarData } from "../../../../../utils/utils";
import { documentMask } from "../../../../../utils/documentMask";
import { postalCodeFormatter } from "../../../../../utils/postalCodeFormatter";

export const InvoiceAddress = ({ order }) => {
  return (
    <View>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View style={[styles.headerSection, { gap: 2 }]}>
          <Text>Cliente</Text>
          <Text style={styles.headerSectionItem}>
            Cliente: {order.clientes.fantasia || order.clientes.razao}
          </Text>

          <Text style={styles.headerSectionItem}>
            CNPJ/CPF: {documentMask(order.clientes.cnpjCpf)}
          </Text>

          <Text style={styles.headerSectionItem}>
            E-mail: {order.clientes.email}
          </Text>

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text style={styles.headerSectionItem}>
              Fone1: {order.clientes.fone1}
            </Text>

            {order.clientes.fone2 && (
              <Text style={styles.headerSectionItem}>
                Fone2: {order.clientes.fone2}
              </Text>
            )}
          </View>
        </View>

        <View style={styles.headerSection}>
          <Text>Pagamento</Text>

          <Text style={styles.headerSectionItem}>
            Condição de Pgto: {order.condPagamento.descricao}
          </Text>
        </View>

        <View style={[styles.headerSection, { gap: 2 }]}>
          <Text>Endereço</Text>
          <Text style={styles.headerSectionItem}>
            Rua: {order.clientes.endereco}, Nº {order.clientes.numero}
          </Text>{" "}
          <Text style={styles.headerSectionItem}>
            Bairro: {order.clientes.bairro}
          </Text>
          <Text style={styles.headerSectionItem}>
            Cidade: {order.clientes.cidade}, {order.clientes.uf}
          </Text>
          <Text style={styles.headerSectionItem}>
            CEP: {postalCodeFormatter(String(order.clientes.cep))}
          </Text>
        </View>
      </View>

      <View style={{ marginTop: 30 }}>
        <Text style={styles.invoice}></Text>
      </View>

      <View style={styles.titleContainer}>
        <View style={styles.spaceBetween}>
          <View>
            <Text
              style={[
                styles.invoice,
                { flexDirection: "row", justifyContent: "space-between" },
              ]}
            >
              <Text style={[styles.textBold, { fontWeight: 800 }]}>
                Nº do Pedido: {order.pedidoOriginal}
              </Text>
            </Text>
          </View>

          <View>
            <Text style={styles.invoice}>
              Faturamento: {formatarData(order.dataFaturamento)}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};
