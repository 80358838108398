import Header from "../components/Header";
import Footer from "../components/Footer"
import { UserIdent } from "../components/controles/UserIdent";

import { Box, Link, Toolbar } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useBreakpoints } from "../hooks/useBreakpoints";

import "react-toastify/dist/ReactToastify.css";

export const DefaultLayout = ({
  children,
  breadcrumbs = [],
  activeBreadcrumb = breadcrumbs[breadcrumbs.length - 1],
  enableModalOnStart,
}) => {
  const isSmallScreen = useBreakpoints("sm");
  const isMediumScreen = useBreakpoints("md");

  return (
    <div className="wrapper">
      <ToastContainer />
      <Header enableModalOnStart={enableModalOnStart} />

      <Toolbar />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-12 col-md-5">
                    <UserIdent />
                  </div>
                  <div className="col-sm-7">
                    <ol
                      className={`breadcrumb float-sm-${isMediumScreen ? "left" : "right"
                        }`}
                    >
                      {!isSmallScreen &&
                        breadcrumbs.length > 0 &&
                        breadcrumbs.map((breadcrumb) => (
                          <li
                            className={`breadcrumb-item ${breadcrumb === activeBreadcrumb
                              ? "active text-orange"
                              : ""
                              }`}
                            key={`${breadcrumb.url}-${breadcrumb.label}`}
                          >
                            <Link
                              href={breadcrumb.url}
                              sx={{ textDecoration: "none" }}
                              color="inherit"
                            >
                              {breadcrumb.label}
                            </Link>
                          </li>
                        ))}
                    </ol>
                  </div>
                </div>

                <Box sx={{}}>{children}</Box>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
